import Swiper, {Autoplay, Navigation, Scrollbar} from 'swiper';

export function inizializeCollectionSwiper() {
    const swiperWrapper = document.querySelector('.spielraeumeSwiper');
    if(swiperWrapper) {
        const swiperElements = swiperWrapper.querySelectorAll(".swiper-slide").length

        if(swiperElements > 1) {
            var swiper = new Swiper(".spielraeumeSwiper", {
                slidesPerView: 2,
                spaceBetween: 8,
                loop: false,
                centeredSlides: true,
                initialSlide: 1,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        } else {
            swiperWrapper.querySelector('.swiper-interaction').classList.add('hidden');
        }

    }
}