import Swiper, {Autoplay, Navigation, Scrollbar} from 'swiper';
import {getOptionsFromJsonString} from "../../../../default/global/js/utilities/options";

export function modalSlider() {

    Swiper.use([Autoplay, Navigation]);
    var modalSwiper = new Swiper('.swiper-container-modal', {
        loop: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            slideChangeTransitionEnd: function () {
                // Handle slide change if needed
            }
        }
    });

    // Add click event listeners to the icons
    // Add click event listeners to the icons
    var iconElements = document.querySelectorAll('.attributesIcons__img');
    iconElements.forEach(function (element, index) {
        element.addEventListener('click', function () {
            modalSwiper.slideTo(index, 0);
        });
    });
}