import {autocomplete, getAlgoliaResults} from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import historyRouter from 'instantsearch.js/es/lib/routers/history';
import {connectSearchBox} from 'instantsearch.js/es/connectors';
import {configure, hierarchicalMenu, hits, pagination} from 'instantsearch.js/es/widgets';

import '@algolia/autocomplete-theme-classic';

export function algoliaDoAutoComplete() {

    document.querySelector('.js-closeSearchWrapper').addEventListener('click', function() {
        var searchWrapper = document.querySelector('.algoliaSearchWrapper');
        if (searchWrapper) {
            searchWrapper.classList.add('aa-notVisible');
        }
    });

    const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);
    let autoComplete = document.querySelector('.algoliaSearchWrapper');
    let extraSearchBox = document.querySelector('.algoliaExtraSearchWrapper');
    let orderNo = GLOBALS.tc.orderNo;

    if (autoComplete) {
        autocomplete({
            container: '.headerMain .autocomplete__search',
            panelContainer: '.headerMain .autocomplete__results',
            placeholder: searchPlaceHolder,
            detachedMediaQuery: 'none',
            onSubmit(params) {
                if (params.state.query) {
                    location.href = `/${customizedUrl}/search/?input_search=${params.state.query}`;
                }
            },
            getSources({query}) {
                return [{
                    sourceId: 'items',
                    getItems() {
                        return getAlgoliaResults({
                            searchClient, queries: [{
                                indexName: algoliaIndexName, query, params: {
                                    hitsPerPage: 12,
                                },
                            },],
                        });
                    },
                    templates: {
                        item({item, components, html}) {
                            return html`
                                <div class="aa-ItemWrapper">
                                    <div class="aa-ItemContent">
                                        <div class="aa-ItemContentBody itembox">
                                            <a href="/${customizedUrl}${item.itemLink}" class="itembox__main js-preventPanelClose">
                                                <span class="itembox__hoverImg--wrapper">
                                                     <img class="aa-ItemContentImage itembox__hoverImg" src="${item.itemImageHoverLink}" alt=""/>
                                                </span> 
                                                <div class="itembox__head">
                                                    <div class="itembox__actions d-none">
                                                        <div class="itembox__actionsWrapper">
                                                            <button class="comparisonBtn js-addItemToComparison actionIcon" data-id="${item.itemId}">
                                                                <i class="icon icon-produktvergleich"></i>
                                                            </button>

                                                            <button class="actionIcon js-addToFavorites" title="Zu Favoriten hinzufügen" data-id="${item.itemId}">
                                                                <i class="icon icon-favorites"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="aa-ItemContentImage itembox__image">
                                                        <img class="aa-ItemContentImage" src="${item.itemImage}" alt=""/>
                                                    </div> 
                                                </div>
                                                <div class="aa-ItemContentDescription itembox__content">
                                                    <span class="aa-ItemContentTitle itembox__name">${item.description}</span>
                                                    <span class="itemcardSummary marginBottom">${item.summary}</span>
                                                    <span class="aa-ItemContentTitle itembox__itemno">${orderNo} ${item.itemNo}</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            `;
                        },
                    }
                }];
            },
        });
    }

    if (extraSearchBox) {
        autocomplete({
            container: '.autocomplete__extra_search',
            panelContainer: '.autocomplete__extra_results',
            placeholder: searchPlaceHolder,
            detachedMediaQuery: 'none',
            openOnFocus: true,
            onSubmit(params) {
                if (params.state.query) {
                    location.href = `/${customizedUrl}/search/?input_search=${params.state.query}`;
                }
            },
            getSources({query}) {
                return [{
                    sourceId: 'items',
                    getItems() {
                        return getAlgoliaResults({
                            searchClient, queries: [{
                                indexName: algoliaIndexName, query, params: {
                                    hitsPerPage: 12,
                                },
                            },],
                        });
                    },
                    templates: {
                        item({item, components, html}) {
                            return html`
                                <div class="aa-ItemWrapper">
                                    <div class="aa-ItemContent">
                                        <div class="aa-ItemContentBody itembox">
                                            <a href="/${customizedUrl}${item.itemLink}" class="itembox__main js-preventPanelClose">
                                                <span class="itembox__hoverImg--wrapper">
                                                    <img class="aa-ItemContentImage itembox__hoverImg" src="${item.itemImage}" alt=""/>
                                                </span>
                                                <div class="itembox__head">
                                                    <div class="itembox__actions">
                                                        <div class="itembox__actionsWrapper">
                                                            <button class="comparisonBtn js-addItemToComparison actionIcon" data-id="${item.itemId}">
                                                                <i class="icon icon-produktvergleich"></i>
                                                            </button>

                                                            <button class="actionIcon js-addToFavorites" title="Zu Favoriten hinzufügen" data-id="${item.itemId}">
                                                                <i class="icon icon-favorites"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="aa-ItemContentImage itembox__image">
                                                        <img class="aa-ItemContentImage" src="${item.itemImage}" alt=""/>
                                                    </div>
                                                </div>
                                                <div class="aa-ItemContentDescription itembox__content">
                                                    <span class="aa-ItemContentTitle itembox__name">${item.description} Produktname</span>
                                                    <span class="itemcardSummary marginBottom">${item.summary}</span>
                                                    <span class="aa-ItemContentTitle itembox__itemno">Bestellnummer</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            `;
                        },
                    }
                }];
            },
        });

    }
}
