/*jshint esversion: 6 */
import {XhrService} from "../ts/xhr.service";
import {addActionClass, addErrorClass, addLoadingClass, addSuccessClass} from "./utilities/updateClasses";
import {appendScriptToDOM} from "./utilities/functions";

function addItemToComparison(itemid, beforeSend, successSend, errorSend) {
    const fd = new FormData();
    fd.append("itemId", itemid);
    if (typeof beforeSend !== 'undefined') {
        beforeSend();
    }

    XhrService.generalAjaxCall('addItemToComparison', fd, 'POST', 'json').then(res => {
        if (typeof successSend !== 'undefined') {
            successSend(res);
        }
    }, err => {
        console.error(err);
        if (typeof errorSend !== 'undefined') {
            errorSend();
        }
        console.log(res);
    })
}

function removeItemFromComparison(itemid, beforeSend, successSend, errorSend) {
    const fd = new FormData();
    fd.append("itemId", itemid);
    if (typeof beforeSend !== 'undefined') {
        beforeSend();
    }

    XhrService.generalAjaxCall('removeItemFromComparison', fd).then(res => {
        if (typeof successSend !== 'undefined') {
            successSend(res);
        }
    }, err => {
        console.error(err);
        if (typeof errorSend !== 'undefined') {
            errorSend();
        }
    })
}

function updateComparisonCount(add = true) {
    for (let count of document.querySelectorAll('.js-comparisonCount')) {
        let value = count.innerHTML;
        if (value == "") {
            value = 0;
        }
        value = parseInt(value);
        if (add) {
            count.innerHTML = value + 1;
        } else {
            if (value > 0) {
                count.innerHTML = value - 1;
            }
        }
        if (parseInt(count.innerHTML) === 0) {
            count.style.display = "none";
        } else {
            addActionClass(count, function () {
                count.style.display = "";
            });
        }
    }
}

function toggleComparisonBtnClasses(el) {
    let icon = el.querySelector('.icon');
    let filledIconClass = 'icon-comparison-filled';
    let emptyIconClass = 'icon-comparison';
    if (el.classList.contains("js-addItemToComparison")) {
        el.classList.remove("js-addItemToComparison");
        el.classList.add("js-removeItemFromComparison");

        if (icon) {
            icon.classList.remove(emptyIconClass);
            icon.classList.add(filledIconClass);
        }
    } else {
        el.classList.remove("js-removeItemFromComparison");
        el.classList.add("js-addItemToComparison");

        if (icon) {
            icon.classList.remove(filledIconClass);
            icon.classList.add(emptyIconClass);
        }
    }
}

export function triggerComparisonBtns() {
    document.addEventListener("click", function (e) {
        let target = e.target;

        if (target.closest(".js-addItemToComparison")) {
            let itemID = target.dataset.id;
            if (itemID) {
                if (document.body.classList.contains("productcomparison")) {
                    addLoadingClass(target);
                    window.location.href = "?action=addItemToComparison&itemId=" + itemID;
                }else{
                    addItemToComparison(itemID, () => {
                        addLoadingClass(target);
                    }, (result) => {
                        console.log(result);
                        if (result.successful) {
                            addSuccessClass(target);
                            toggleComparisonBtnClasses(target);
                            updateComparisonCount(true);
                            if (result.tagManager) {
                                appendScriptToDOM(result.tagManager, true);
                            }
                        } else {
                            addErrorClass(target);
                        }
                    }, () => {
                        addErrorClass(target);
                    });
                }
            } else {
                console.error("item id not found");
            }
            e.preventDefault();
        }

        if (target.closest(".js-removeItemFromComparison")) {
            const itemID = target.dataset.id;
            if (itemID) {
                if (document.body.classList.contains("productcomparison")) {
                    addLoadingClass(target);
                    window.location.href = "?action=removeItemFromComparison&itemId=" + itemID;
                } else {
                    removeItemFromComparison(itemID, () => {
                        addLoadingClass(target);
                    }, (result) => {
                        addSuccessClass(target);
                        toggleComparisonBtnClasses(target);
                        updateComparisonCount(false);
                    }, () => {
                        addErrorClass(target);
                    });
                }
            } else {
                console.error("item id not found");
            }
            e.preventDefault();
        }
    });
}