export function focusInputGroups(query = document.querySelectorAll('.inputGroup input,.inputGroup select,.inputGroup textarea')) {
    for (let input of query) {
        let inputGroup = input.closest(".inputGroup");
        let infieldInputGroup = input.closest(".infieldInputGroup");

        checkFilledInput(input);

        input.addEventListener("focus", function () {
            inputGroup.classList.add("is-focus");
            if (infieldInputGroup) {
                infieldInputGroup.classList.add("is-focus");
            }
        });

        input.addEventListener("blur", function () {
            inputGroup.classList.remove("is-focus");
            if (infieldInputGroup) {
                infieldInputGroup.classList.remove("is-focus");
            }
        });

        input.addEventListener("input", function () {
            checkFilledInput(input);
        });
    }
}

export function checkFilledInput(input) {
    let inputGroup = input.closest(".inputGroup");
    let infieldInputGroup = input.closest(".infieldInputGroup");

    function updateInputClasses() {
        let hasValue;
        try {
            hasValue = input.matches(':autofill');
        } catch (err) {
            try {
                hasValue = input.matches(':-webkit-autofill');
            } catch (er) {
                hasValue = false;
            }
        }
        const isFilled = input.value !== "" || hasValue;

        if (inputGroup) {
            isFilled ? inputGroup.classList.add("is-filled") : inputGroup.classList.remove("is-filled");
        }

        if (infieldInputGroup) {
            isFilled ? infieldInputGroup.classList.add("is-filled") : infieldInputGroup.classList.remove("is-filled");
        }

        if (input.dataset.intlTelInputId !== undefined) {
            if (inputGroup) inputGroup.classList.add("is-focus");
            if (infieldInputGroup) infieldInputGroup.classList.add("is-focus");
        }
    }

    updateInputClasses();
    const observer = new MutationObserver(updateInputClasses);
    observer.observe(input, { attributes: true, attributeFilter: ['class', 'autofill'] });

    const intervalId = setInterval(updateInputClasses, 200);

    input.addEventListener('focus', updateInputClasses);

    setTimeout(() => {
        clearInterval(intervalId);
        observer.disconnect();
        input.removeEventListener('focus', updateInputClasses);
    }, 5000);

    return () => {
        clearInterval(intervalId);
        observer.disconnect();
        input.removeEventListener('focus', updateInputClasses);
    };
}