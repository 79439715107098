export function scrollAnimatedText() {
    // Animated heading on scroll .scrollAnimateHeading
    let scrollAnimateHeadings = document.querySelectorAll('.animatedText');
    window.addEventListener('scroll', function () {
        let windowHeight = window.innerHeight;

        scrollAnimateHeadings.forEach(function(scrollAnimateHeading) {
            let scrollAnimateHeadingTop = scrollAnimateHeading.getBoundingClientRect().top;
            let spans = scrollAnimateHeading.querySelectorAll('span');

            // Zuerst alle "active" Klassen entfernen
            spans.forEach(function(span) {
                span.classList.remove('active');
            });

            // Bestimme das aktive Element basierend auf der Scroll-Position
            if (scrollAnimateHeadingTop < 0) {
                // Das dritte Element aktivieren, wenn weit genug gescrollt wurde
                if (spans[2]) spans[2].classList.add('active');
            } else if (scrollAnimateHeadingTop < (windowHeight / 2)) {
                // Das zweite Element aktivieren
                if (spans[1]) spans[1].classList.add('active');
            } else {
                // Das erste Element aktivieren
                if (spans[0]) spans[0].classList.add('active');
            }
        });
    });
}

export function scrollAnimatedTextTop() {
    // Animated heading on scroll .scrollAnimateHeading
    let scrollAnimateHeadings = document.querySelectorAll('.animatedTextTop');
    window.addEventListener('scroll', function () {
        let windowHeight = window.innerHeight;

        scrollAnimateHeadings.forEach(function(scrollAnimateHeading) {
            let scrollAnimateHeadingTop = scrollAnimateHeading.getBoundingClientRect().top;
            let spans = scrollAnimateHeading.querySelectorAll('span');

            // Zuerst alle "active" Klassen entfernen
            spans.forEach(function(span) {
                span.classList.remove('active');
            });

            // Bestimme das aktive Element basierend auf der Scroll-Position
            if (scrollAnimateHeadingTop < 0) {
                // Das dritte Element aktivieren, wenn weit genug gescrollt wurde
                if (spans[2]) spans[2].classList.add('active');
            } else if (scrollAnimateHeadingTop < (windowHeight / 6) && scrollAnimateHeadingTop >= 0) {
                // Das zweite Element aktivieren, wenn in der Mitte des Bildschirms
                if (spans[1]) spans[1].classList.add('active');
            } else {
                // Das erste Element aktivieren, wenn noch oben auf dem Bildschirm
                if (spans[0]) spans[0].classList.add('active');
            }
        });
    });
}