export function initActionClicks(query = document.querySelectorAll(".js-actionClick")) {
    for (let target of query) {
        let action = target.dataset.action;
        if (action) {
            target.addEventListener("click", function () {
                window.location.href = action;
            });
        }
    }
}

export function initFormSubmitClicks(query = document.querySelectorAll(".js-formSubmitClick")) {
    for (let target of query) {
        target.addEventListener("click", function () {
            console.log(target);
            target.closest('form').submit();
        });
    }
}

export function initFormSubmitChanges(query = document.querySelectorAll(".js-formSubmitChange")) {
    for (let target of query) {
        target.addEventListener("change", function () {
            target.closest('form').submit();
        });
    }
}