import {fadeIn, fadeOut} from "./utilities/fade";

function openFilterBox(filterbox) {
    if (filterbox) {
        document.body.style.overflow = "hidden";
        if (window.outerWidth < 768) {
            fadeIn(document.getElementById("overlay"));
        }
        filterbox.classList.add("is-active");
    }
}

export function closeFilterBox(filterbox) {
    if (filterbox) {
        filterbox.classList.remove("is-active");
        document.body.style.overflow = "";

        fadeOut(document.getElementById("overlay"));
    }
}

function openFilterItem(filterItem) {
    filterItem.classList.add("is-active");
}

function closeFilterItem(filterItem) {
    filterItem.classList.remove("is-active");
}

function closeAllFilterItems() {
    for (let filterItem of document.querySelectorAll('.filterItem')) {
        closeFilterItem(filterItem);
    }
}

function showHiddenFilters(filterbox) {
    for (let hiddenFilter of filterbox.querySelectorAll('.hiddenFilter')) {
        hiddenFilter.style.display = "block";
    }
}

function hideHiddenFilters(filterbox) {
    for (let hiddenFilter of filterbox.querySelectorAll('.hiddenFilter')) {
        hiddenFilter.style.display = "none";
    }
}

export function initFilter() {
    for (let filterbox of document.querySelectorAll('.filterBox')) {
        let filterMobileBtn = filterbox.querySelector('.filterBox__mobileButton');
        let filterClose = filterbox.querySelector('.filterBox__close');
        let filterHiddenToggle = filterbox.querySelector('.filterBox__showHiddenFilters');

        if (filterMobileBtn) {
            filterMobileBtn.addEventListener("click", function () {
                if (filterbox.classList.contains("is-active")) {
                    closeFilterBox(filterbox);
                } else {
                    openFilterBox(filterbox);
                }
            })
        }

        if (filterClose) {
            filterClose.addEventListener("click", function () {
                closeFilterBox(filterbox);
            })
        }

        if (filterHiddenToggle) {
            filterHiddenToggle.addEventListener("click", function () {
                if(filterHiddenToggle.classList.contains("active")){
                    hideHiddenFilters(filterbox);
                    filterHiddenToggle.classList.remove("active");
                    filterHiddenToggle.innerHTML = "+&nbsp;" + GLOBALS.tc.moreFilter;
                }else{
                    showHiddenFilters(filterbox);
                    filterHiddenToggle.classList.add("active");
                    filterHiddenToggle.innerHTML = "-&nbsp;" + GLOBALS.tc.lessFilter;
                }
            })
        }
    }
    document.addEventListener("click", function (e) {
        closeAllFilterItems();
        if (e.target.closest(".filterItem")) {
            let filterItem = e.target.closest(".filterItem");
            if (filterItem.classList.contains("is-active")) {
                closeFilterItem(filterItem);
            } else {
                openFilterItem(filterItem);
            }
        }
    });
}