function showPriceListGraduated(el) {
    if (el) {
        el.classList.add("is-active");
        // Fix z-index
        if(el.closest(".itemlistElement")){
            el.closest(".itemlistElement").classList.add("show-priceListGraduated");
        }
    }
}

export function hidePriceListGraduated(el) {
    if (el) {
        el.classList.remove("is-active");
        // Fix z-index
        if(el.closest(".itemlistElement")){
            el.closest(".itemlistElement").classList.remove("show-priceListGraduated");
        }
    }
}

function hideAllPriceListGraduated() {
    for (let el of document.querySelectorAll('.priceListGraduated')) {
        hidePriceListGraduated(el);
    }
}

export function initPriceListGraduated() {
    document.addEventListener("click", function (e) {
        hideAllPriceListGraduated();
        if (e.target.closest(".priceListGraduated")) {
            let el = e.target.closest(".priceListGraduated");
            if (el.classList.contains("is-active")) {
                hidePriceListGraduated(el);
            } else {
                showPriceListGraduated(el);
            }
        }
    });
}