import {addErrorClass, addLoadingClass, removeLoadingClass} from "./utilities/updateClasses";
import {wrap} from "./utilities/wrap";

let currentFilterID = null;

function setActiveClassForFilter(btn, wrapper) {
    for (let btn of wrapper.querySelectorAll(".js-collectionFilter .tag")) {
        btn.classList.remove("is-active");
    }
    btn.classList.add("is-active");
}

function updatePaginationLinks(filterID) {
    for (let link of document.querySelectorAll(".pagination__item")) {
        let url = new URL(link.href);
        url.searchParams.set("group_filter", filterID);
        link.href = url.toString();
    }
}

export function initAjaxCollectionFilter() {
    for (let btn of document.querySelectorAll(".js-collectionFilter .tag")) {
        let wrapper = btn.closest(".collection");
        if (wrapper) {
            let wrapperId = wrapper.getAttribute("id");
            let area = wrapper.querySelector(".collectionArea");
            if (area) {
                btn.addEventListener("click", function (e) {
                    e.preventDefault();
                    addLoadingClass(btn);
                    addLoadingClass(area);
                    currentFilterID = parseInt(btn.dataset.filter);
                    fetch("?group_filter=" + currentFilterID)
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Could not filter data");
                            }
                            return response.text();
                        })
                        .then((data) => {
                            let parser = new DOMParser();
                            let doc = parser.parseFromString(data, "text/html");
                            let newWrapper = doc.getElementById(wrapperId);
                            if (newWrapper) {
                                let newArea = newWrapper.querySelector(".collectionArea");
                                if (newArea) {
                                    area.innerHTML = newArea.innerHTML;
                                    removeLoadingClass(btn);
                                    removeLoadingClass(area);
                                    setActiveClassForFilter(btn, wrapper);
                                    updatePaginationLinks(currentFilterID);
                                    window.history.replaceState(null, null, "?group_filter=" + currentFilterID);
                                } else {
                                    console.error('Area not found');
                                }
                            } else {
                                console.error('Wrapper not found');
                            }
                        })
                        .catch((e) => {
                            console.error('Error:', e);
                            addErrorClass(btn);
                            addErrorClass(area);
                        });
                });
            }
        }
    }

    // Update pagination links on page load if a filter is applied
    if (currentFilterID !== null) {
        updatePaginationLinks(currentFilterID);
    }

    // Ensure the filter value is maintained on page load
    document.addEventListener("DOMContentLoaded", function () {
        const urlParams = new URLSearchParams(window.location.search);
        const filterID = urlParams.get('group_filter');
        if (filterID) {
            currentFilterID = parseInt(filterID);
            const activeFilterBtn = document.querySelector(`.js-collectionFilter .tag[data-filter="${filterID}"]`);
            if (activeFilterBtn) {
                setActiveClassForFilter(activeFilterBtn, document.querySelector(".collection"));
            }
            updatePaginationLinks(currentFilterID);
        }
    });
}
